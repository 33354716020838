import React , {  useState } from 'react'
import logo from "./images/logo.png";
import gameicn1 from "./images/gameicn1.png";
import gameicn2 from "./images/gameicn2.png";
import gameicn3 from "./images/gameicn3.png";
import gameicn4 from "./images/gameicn4.png";
import gameicn5 from "./images/gameicn5.png";
import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Games = () => {
  const[state, setState] = useState();
  const _handleClick = id => {
    setState({ [id]: true });
  };
  const _handleClick2 = id => {
    setState({ [id]: false });
  };


    var settinggames = {
        className: "games_sliderlop metavers_slider_team",
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current, next) => setState(false),
        responsive: [
 
          {
          breakpoint: 767,
          settings: {
            centerMode: false,
           slidesToShow: 1,
           slidesToScroll: 1
          }
          },
          {
          breakpoint: 480,
          settings: {
            centerMode: false,
           slidesToShow: 1,
           slidesToScroll: 1
          }
          }
          
          ]
      };
      
 


  return (
    <div className='games_page_waspper'>
    <header className="site-header head_same">
        
    <div className="container_rows">
        <div className="siteheader">
        <div className="logo_box">
        <div className="logo_icon">
            <Link to={"/"}> <img  src={logo} alt="logo"/></Link>
        </div>
        </div>





<div className="wallet_box_outer">
        <ul className="menu-nev">
        <li><Link to={"/profile"}> Profile</Link></li>
        <li className='actve'><Link to={"/games"}> Games</Link></li>
        <li ><Link to={"/metaverses"}> Metaverses</Link></li>
        <li><Link to="#"> Connect Wallet</Link></li>
        </ul>


</div>      

<div className="clear"></div>


    </div>
</div>


</header>


<section className="games_slider">
<div className="container_rows"> 

<Slider {...settinggames}>


<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={gameicn2} alt="icon"/></div>
<div className="metavers-tile">Metarun</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 3k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://metarun.game/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button1" onClick={() => _handleClick("button1")}> More Info </div></div></div>
</div>
{state && state.button1 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Metarun</h2><p>Metarun is the world's first-ever P2E, PvE/PvP endless mobile runner game universe that empowers its players with fully customizable ownership of NFT’s.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://metarun.game/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button1" onClick={() => _handleClick2("button1")}> Go Back </div></div></div>

</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={gameicn1} alt="icon"/></div>
<div className="metavers-tile">Aavegotchi</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 9k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.aavegotchi.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button2" onClick={() => _handleClick("button2")}> More Info </div></div></div>
</div>
{state && state.button2 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Aavegotchi</h2><p>Aavegotchi is an open-source, community-owned NFT gaming protocol, enabling true asset ownership for gamers.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.aavegotchi.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button2" onClick={() => _handleClick2("button2")}> Go Back </div></div></div>
</div></div>}
</div></div>


<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={gameicn3} alt="icon"/></div>
<div className="metavers-tile">Revolve Games</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 4k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://revolvegames.io/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button3" onClick={() => _handleClick("button3")}> More Info </div></div></div>
</div>
{state && state.button3 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Revolve Games</h2><p>Revolve Games is revolutionizing the way DeFi staking is integrated with blockchain gaming. The staking rewards of a given tethered actionable NFT are dependant on the in-game performance of that asset; be it a character, vehicle or game builder.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://revolvegames.io/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button3" onClick={() => _handleClick2("button3")}> Go Back </div></div></div>
</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={gameicn4} alt="icon"/></div>
<div className="metavers-tile">Star Atlas</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 8k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://staratlas.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button4" onClick={() => _handleClick("button4")}> More Info </div></div></div>
</div>
{state && state.button4 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Star Atlas</h2><p>A grand strategy game of space exploration, territorial conquest, political domination, and more.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://staratlas.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button4" onClick={() => _handleClick2("button4")}> Go Back </div></div></div>
</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={gameicn5} alt="icon"/></div>
<div className="metavers-tile">Codyfight</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 2k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://codyfight.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button5" onClick={() => _handleClick("button5")}> More Info </div></div></div>
</div>
{state && state.button5 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Codyfight</h2><p>Codyfight is AI vs. Human competition metaverse beyond gaming, where players can use their skills by playing, coding, drawing, hosting custom games and also earn crypto rewards.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://codyfight.com/">Play</a>   </div>
<div className="more-btn"><div className="fircle" id="button5" onClick={() => _handleClick2("button5")}> Go Back </div></div></div>
</div></div>}
</div></div>





</Slider>
</div>
</section>
      










    </div>
  )
}

export default Games
