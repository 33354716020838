import {BrowserRouter,Routes,Route,} from "react-router-dom";
import Homapage from "./Homapage";
//import Loginpage from "./Loginpage";
import Metaverses from "./Metaverses";
import Games from "./Games";
import Profile from "./Profile";
import './App.css';
import './css/style.css';
import './css/responsive.css';
import './css/slick.css';
import './css/font-awesome.css';
//import './js/slick.js';
import $ from 'jquery';
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

function App() {
  
// $(document).ready(function() {
// $('.split').each(function( ) {
//   var allSplit = $(this);
//   var wordList = $(this).text().split("");
//   $(this).text(""); 
//   $.each(wordList, function (idx, elem) {
//       allSplit.append('<span style=--i:'+idx+'>'+elem+'</span>');
//   });
// });


// });
///

















  return (
    <>
    <BrowserRouter  >
<Routes>
 
  <Route path="/" element={<Homapage />}/>
  <Route path="/metaverses" element={<Metaverses />}/>
  <Route path="/games" element={<Games />}/>
  <Route path="/profile" element={<Profile />}/>
  
</Routes>
</BrowserRouter>
    </>
  );
}

export default App;
