import React from 'react'
import foot_logo from "./images/foot-logo.png";
import foot_logo2 from "./images/foot-logo2.png";
import signuparo from "./images/Signup.png";
import social1 from "./images/social1.png";
import social2 from "./images/social2.png";
import social3 from "./images/social3.png";
import social4 from "./images/social4.png";
import social5 from "./images/social5.png";
import AnchorLink from 'react-anchor-link-smooth-scroll';
const Footer = () => {
  return (
    <div className='footer_waspper'>
    <div className="footer">
    <div className="container_rows"> 

<div  className="footer-widget-outer">




<div  className="footer-widget">
<div  className="footer-widget-logo">

<img  src={foot_logo} alt="icon"/>
</div>
<div  className="footer-widget-menu">


<ul>
    <li><AnchorLink offset='200' href='#bannerid'>Portal to Top</AnchorLink></li>
    <li><AnchorLink offset='50' href='#whatprtsl'>What is Portal</AnchorLink></li>
    <li><AnchorLink offset='50' href='#platformid'>Interface </AnchorLink></li>
    <li><AnchorLink offset='50' href='#portaloverview'>Portal Overview </AnchorLink></li>
    <li><AnchorLink offset='50' href='#roadmapid'>Roadmap</AnchorLink></li>
    <li><AnchorLink offset='50' href='#ourteamid'>Our Team</AnchorLink></li>
</ul>

</div>

</div>


<div  className="footer-widget">
<div  className="footer-center newsleterbx">
<h3>Newsletter</h3>
<p>Sign up to mailing list to be notified when the platform launches</p>
     <div  className="newsletter-widget"><form className="news_exaple" action="#">
                 <input type="text" placeholder="Sign up" name="newsletter" /> <button type="submit"><img  src={signuparo} alt="search"/></button>
                </form></div>
</div>
</div>



<div  className="footer-widget">
<div  className="footer-center get-redy">
<img  src={foot_logo2} alt="icon"/>
<p>“Get ready for fhe future”</p>
</div>
</div>




</div>





<div className="copy-right-outer">
<div className="copyright">Copyright © 2022 PORTAL</div>
<div className="social-icon">
    <ul>
    <li><a target="_blank" href="#"><img  src={social1} alt="icon"/></a></li>
    <li><a target="_blank" href="https://twitter.com/useportal_io"><img  src={social2} alt="icon"/></a></li>
    <li><a target="_blank" href="#"><img  src={social3} alt="icon"/></a></li>
    <li><a target="_blank" href="#"><img  src={social4} alt="icon"/></a></li>
    <li><a target="_blank" href="https://discord.gg/rYX252xg"><img  src={social5} alt="icon"/></a></li>
  
</ul>

</div>      <div className="clear"></div>
</div>
</div>
</div>

    </div>
  )
}

export default Footer
