import React , {  useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "./images/logo.png";
import metavers1 from "./images/metavers1.png";
import metavers2 from "./images/metavers2.png";
import metavers3 from "./images/metavers3.png";
import metavers4 from "./images/metavers4.png";
import metavers5 from "./images/metavers5.png";
import Slider from "react-slick";


const Metaverses = () => {

  const[state, setState] = useState();
  const _handleClick = id => {
    setState({ [id]: true });
  };
  const _handleClick2 = id => {
    setState({ [id]: false });
  };


    var settingmetaves = {
        className: "metavers_sliderlop metavers_slider_team",
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current, next) => setState(false),
        responsive: [
 
          {
          breakpoint: 767,
          settings: {
            centerMode: false,
           slidesToShow: 1,
           slidesToScroll: 1
          }
          },
          {
          breakpoint: 480,
          settings: {
            centerMode: false,
           slidesToShow: 1,
           slidesToScroll: 1
          }
          }
          
          ]
      };



  return (
    <div className='metavers_page_waspper'>
    <header className="site-header head_same">
        
    <div className="container_rows">
        <div className="siteheader">
        <div className="logo_box">
        <div className="logo_icon">
            <Link to={"/"}> <img  src={logo} alt="logo"/></Link>
        </div>
        </div>





<div className="wallet_box_outer">
        <ul className="menu-nev">
        <li><Link to={"/profile"}> Profile</Link></li>
        <li><Link to={"/games"}> Games</Link></li>
        <li className='actve'><Link to={"/metaverses"}> Metaverses</Link></li>
        <li><Link to="#"> Connect Wallet</Link></li>
        </ul>


</div>      

<div className="clear"></div>


    </div>
</div>


</header>


<section className="metavers_slider">
<div className="container_rows"> 

<Slider {...settingmetaves}>




<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={metavers1} alt="icon"/></div>
<div className="metavers-tile">Otherside</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 6k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://otherside.xyz/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button1" onClick={() => _handleClick("button1")}> More Info </div></div></div>
</div>
{state && state.button1 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Otherside</h2><p>Otherside is a gamified, interoperable metaverse. The game blends mechanics from massively multiplayer online role playing games (MMORPGs) and web3-enabled virtual worlds.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://otherside.xyz/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button1" onClick={() => _handleClick2("button1")}> Go Back </div></div></div>

</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={metavers2} alt="icon"/></div>
<div className="metavers-tile">NetVRK</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 4k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.netvrk.co/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button2" onClick={() => _handleClick("button2")}> More Info </div></div></div>
</div>
{state && state.button2 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>NetVRK</h2><p>Virtual Land enables users in the Netvrk Metaverse to deploy assets, monetize their land, as well as receive Netvrk Reserve distributions.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.netvrk.co/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button2" onClick={() => _handleClick2("button2")}> Go Back </div></div></div>
</div></div>}
</div></div>


<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={metavers3} alt="icon"/></div>
<div className="metavers-tile">Illuvium</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 7k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.illuvium.io/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button3" onClick={() => _handleClick("button3")}> More Info </div></div></div>
</div>
{state && state.button3 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Illuvium</h2><p>Illuvium is an open-world exploration, NFT creature collector and autobattler game built on the Ethereum Blockchain. Play-to-earn in a graphically-rich sci-fi adventure and conquer the wilderness to help your crash-landed crew flourish.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.illuvium.io/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button3" onClick={() => _handleClick2("button3")}> Go Back </div></div></div>
</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={metavers4} alt="icon"/></div>
<div className="metavers-tile">Bloktopia</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 9k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.bloktopia.com/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button4" onClick={() => _handleClick("button4")}> More Info </div></div></div>
</div>
{state && state.button4 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Bloktopia</h2><p>Bloktopians will be able to earn revenue through real estate ownership, advertising revenue, play games, build networks and much more.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://www.bloktopia.com/">Play</a> </div>
<div className="more-btn"><div className="fircle" id="button4" onClick={() => _handleClick2("button4")}> Go Back </div></div></div>
</div></div>}
</div></div>

<div className="metavers-loop"><div className="metavers-loop-iiner">
<div className="metavers-img"><img  src={metavers5} alt="icon"/></div>
<div className="metavers-tile">Universe Island</div>
<div className="metavers-fondr"><p><i className="fa fa-circle" aria-hidden="true"></i> 3k People Online</p> 
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://universeisland.games/">Play</a> </div>
<div className="more-btn"><div className="fircle" id="button5" onClick={() => _handleClick("button5")}> More Info </div></div></div>
</div>
{state && state.button5 && <div className="metavers-fondr-hover"><div className="metavers-hover-inner"><div className="meta-inner-head"><h2>Universe Island</h2><p>A crypto-powered Sci-Fi 1vs1 online shooter game with detailed maps and attractive characters.</p> </div>
<div className="btn-play-more"><div className="play-btn"> <a target="_blank" href="https://universeisland.games/">Play</a>  </div>
<div className="more-btn"><div className="fircle" id="button5" onClick={() => _handleClick2("button5")}> Go Back </div></div></div>
</div></div>}
</div></div>
















</Slider>
</div>
</section>

















    </div>
  )
}

export default Metaverses
