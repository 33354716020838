import React from 'react'
import { Link } from 'react-router-dom';
import logo from "./images/logo.png";
import profilebanrimg from "./images/portal-interface.jpg";
const Profile = () => {
  return (
    <div className='profile_page_waspper'>
    <header className="site-header head_same">  
        
    <div className="container_rows">
        <div className="siteheader">
        <div className="logo_box">
        <div className="logo_icon">
            <Link to={"/"}> <img  src={logo} alt="logo"/></Link>
        </div>
        </div>





<div className="wallet_box_outer">
        <ul className="menu-nev">
        <li className='actve'><Link to={"/profile"}> Profile</Link></li>
        <li ><Link to={"/games"}> Games</Link></li>
        <li ><Link to={"/metaverses"}> Metaverses</Link></li>
        <li><Link to="#"> Connect Wallet</Link></li>
        </ul>


</div>      

<div className="clear"></div>


    </div>
</div>


</header>




<div className="profile_contnt">

  {/*<div className="profile_soon">SOON AVAILABLE</div>*/}
  <div className="profile_contnt-img"><img  src={profilebanrimg} alt="banner"/></div>

</div>













    </div>
  )
}

export default Profile
