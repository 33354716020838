import React, { useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import bannerring from "./images/banner-ring.png";
import prveprotl from "./images/image-removebg-preview.png";
import seicon1 from "./images/icon3.png";
import seicon2 from "./images/icon1.png";
import seicon3 from "./images/icon2.png";
import laptopicon from "./images/group-laptop.png";
import gamechart from "./images/overview-image.png";
import quarterlisticon from "./images/image-removebg-previ.png";
import teamicon1 from "./images/Polygon1.png";
import teamicon2 from "./images/Polygon2.png";
import teamicon3 from "./images/Polygon3.png";
import teamicon4 from "./images/Polygon4.png";
import teamicon5 from "./images/Polygon5.png";
import teamicon6 from "./images/alv.png";
import teamicon7 from "./images/mer.png";
import teamicon8 from "./images/gab.png";
import backerslogo1 from "./images/backers-one.png";
import backerslogo2 from "./images/backers-two.png";
import backerslogo3 from "./images/backers-thre.png";
import backerslogo4 from "./images/backers-four.png";
import backerslogo5 from "./images/backers-five.png";
import backerslogo6 from "./images/backers-six.png";
import landlogo1 from "./images/land-one.png";
import landlogo2 from "./images/land-two.png";
import landlogo3 from "./images/land-thre.png";
import Slider from "react-slick";
import Pdf from './PORTALswHITEPAPERs.pdf';
import { useNavigate } from 'react-router-dom';

import $ from 'jquery'

import { Link } from 'react-router-dom';






const Homapage = () => {






useEffect(()=>{
  $('.split').each(function( ) {
    var allSplit = $(this);
    var wordList = $(this).text().split("");
    $(this).text(""); 
    $.each(wordList, function (idx, elem) {
        allSplit.append('<span style=--i:'+idx+'>'+elem+'</span>');
    });
  });



  // const main_banner = $(".banner-box");
  // main_banner.on("mousemove", function (e) {
  //     var w = $(window).width();
  //     var h = $(window).height();
  //     var offsetX = 0.5 - e.pageX / w;
  //     var offsetY = 0.2 - e.pageY / h;
  //     $(".banner-img [data-offset]").each(function (i, el) {
  //         var offset = parseInt($(el).data("offset"));
  //         var translate =
  //             "translate3d(" +
  //             Math.round(offsetX * offset) +
  //             "px," +
  //             Math.round(offsetY * offset) +
  //             "px, 0px)";
  //         $(el).css({
  //             "-webkit-transform": translate,
  //             transform: translate,
  //             "moz-transform": translate,
  //         });
  //     });
  // });


},[])
 
  

    
/////
  // var marqueeone = {
  //   className: "marqueeloop  maplop",
  //   speed: 5000,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   centerMode: true,
  //   cssEase: 'linear',
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  //   infinite: true,
  //   initialSlide: 1,
  //   arrows: false,
  //   buttons: false
  // };

  // var marqueetwo = {
  //   className: "marqueeloop play_nft",
  //   speed: 5000,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   centerMode: true,
  //   cssEase: 'linear',
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  //   infinite: true,
  //   initialSlide: 1,
  //   arrows: false,
  //   buttons: false
  // };


  // var marqueethre = {
  //   //className: "marqueeloop play_nft",
  //   speed: 5000,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   centerMode: true,
  //   cssEase: 'linear',
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  //   infinite: true,
  //   initialSlide: 1,
  //   arrows: false,
  //   buttons: false

  // };

  var settingsquarter = {
    className: "quarter-box",
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    
    responsive: [
      {
      breakpoint: 1025,
      settings: {
       slidesToShow: 3,
       slidesToScroll: 1
      
      }
      },
      {
      breakpoint: 767,
      settings: {
       slidesToShow: 2,
       slidesToScroll: 1
      }
      },
      {
      breakpoint: 480,
      settings: {
       slidesToShow: 1,
       slidesToScroll: 1
      }
      }
      
      ]
      
  };

  var settingportalteam = {
    className: "portal-team",
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
         slidesToShow: 4,
         slidesToScroll: 1
        
        }
        },
      {
      breakpoint: 1025,
      settings: {
       slidesToShow: 3,
       slidesToScroll: 1
      
      }
      },
      {
      breakpoint: 767,
      settings: {
       slidesToShow: 2,
       slidesToScroll: 1
      }
      },
      {
      breakpoint: 480,
      settings: {
       slidesToShow: 1,
       slidesToScroll: 1
      }
      }
      
      ]
  };
//const username=localStorage.getItem("portal")

//console.log("username",username);
const navigate = useNavigate();

  //const [toggleStatus,setToggle] = useState(false);
  // useEffect(()=>{
  //   if(username=="GETREADY")
  //   {
  //     navigate('/homepage')}
  //     else{
  //       navigate("/")
  //     }
  // })
  // useEffect(()=>{
  //   if(username2=="GETREADY")
  //   {
  //     navigate('/homepage')}
  //     else{
  //       navigate("/")
  //     }
  // })

  return (
    <>
    <div className='home_page_waspper'>
    <Header/>
    <section className="banner-outer" id="bannerid">
    <div className="container_rows">
     <div className="banner-box">
        <div className="banner-text">
            <h1>Powerful <br /> Hybrid Ecosystem <span>PORTAL</span></h1>
           <p>Portal connects metaverses, play-to-earn games and NFT’s, all in one hybrid ecosystem. </p>
           <div className="banner-btn"><Link to={"/metaverses"} target="_blank"  className="open-btn">Open App</Link> <a href={Pdf} target="_blank"  className="whitepaper-btn">Whitepaper</a></div>
        </div>
        <div className="banner-img"><img  src={bannerring} alt="banner" data-offset="-80" /></div> 
        <div className="clear"></div>
    </div>
    </div>
    </section>
 

		<div className="menu_marwu">
			<div className="menu_marq_item">
			
				<div className="marquee">
					<div className="marquee_inner" aria-hidden="true">
		    <p className="split">PLAY TO EARN GAMING ECOSYSTEM</p>
    <p className="split">NFT DEVELOPMENT AND REWARD SYSTEM</p>
    <p className="split">HYBRID SOCIAL METAVERSE PLATFROM</p>
        <p className="split">PLAY TO EARN GAMING ECOSYSTEM</p>
    <p className="split">NFT DEVELOPMENT AND REWARD SYSTEM</p>
    <p className="split">HYBRID SOCIAL METAVERSE PLATFROM</p>
        <p className="split">PLAY TO EARN GAMING ECOSYSTEM</p>
    <p className="split">NFT DEVELOPMENT AND REWARD SYSTEM</p>
    <p className="split">HYBRID SOCIAL METAVERSE PLATFROM</p>
        <p className="split">PLAY TO EARN GAMING ECOSYSTEM</p>
    <p className="split">NFT DEVELOPMENT AND REWARD SYSTEM</p>
    <p className="split">HYBRID SOCIAL METAVERSE PLATFROM</p>
					</div>
				</div>
			</div>
			
			
		</div>


    <section className="cxplore-portal-outer" id="whatprtsl">
    <div className="container_rows"> <div className="cxplore-portal-box">
        <div className="cxplore-portal-img"><img  src={prveprotl} alt="banner"/></div>
        <div className="cxplore-portal-text">
            <h2>PORTAL</h2>
           <div className="cxplore-text">
           <p>Portal is the first hybrid social metaverse platform providing metaverses, play-to-earn games and NFT development all within one interconnected ecosystem and easy accessible through your personal Portal: discover new worlds, buy, use and share assets, meet with friends, play games, earn rewards and much more. 
           </p>
          
       

           </div>
           <div className="cxplore-btn"><Link to={"/metaverses"} target="_blank" className="exp-btn">Explore Portal</Link></div>
        </div>
        
        <div className="clear"></div>
       </div>
    </div>
</section>
    


<section className="metaverse-outer">
   <div className="container_rows">
<div className="metaverse-list">

<div className="list-item-bx">
<div className="item-title">
<div className="title-text">Metaverse Land Revenue</div>
</div>
<div className="item-contenty">
<div className="item-imgy">
<img src={seicon1} alt=""/>
</div>
<div className="metavr-text">
<p>Portal owns land and structures to generate revenue and rewards for token holders.</p>
</div>
</div>
</div>


<div className="list-item-bx">
<div className="item-title">
<div className="title-text">Portal Headquarters</div>
</div>
<div className="item-contenty">
<div className="item-imgy">
<img src={seicon2} alt=""/>
</div>
<div className="metavr-text">
<p>Portal owns headquarters in multiple metaverses, forming a direct connection between these projects.</p>
</div>
</div>
</div>



<div className="list-item-bx">
<div className="item-title">
<div className="title-text">NFT Development & Rewards</div>
</div>
<div className="item-contenty">
<div className="item-imgy">
<img src={seicon3} alt=""/>
</div>
<div className="metavr-text">
<p>Portal NFT sales generate revenue to reward the Portal ecosystem and Portal token holders.</p>
</div>
</div>
</div>

</div>
</div>
</section>






<section className="platform-outer" id="platformid">
    <div className="platform-itm">
    <div className="menu_marwu3">
    <div className="menu_marq_item3">
    
      <div className="marquee3">
        <div className="marquee_inner3" aria-hidden="true">
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        <p>PORTAL  INTERFACE</p>
        
        </div>
      </div>
    </div>
    </div>


    </div>
   <div className="platform-laptop"> <div className="container_rows">
  <div className="platform-img"><img  src={laptopicon} alt="laptop"/></div>

</div>
</div>
</section>


<section className="overview-portal-outer" id="portaloverview">
    <h2>Portal Overview</h2>
    <div className="container_rows"> 

        <div className="overview-portal-box">
        <img  src={gamechart} alt="" />
        {/*<div className="overview-portal-img"><img  src={gamechart} alt="" /></div>
        <div className="overview-portal-text">
            
           <div className="overview-text">
           <p>Portal forms a hybrid connection between metaverses, play-to-earn gaming and NFT's.</p>
           <p>On the Portal platform $PRTL will be used as it's currency. Demand for $PRTL is driven by it's platform ecosystem and attractive rewards. Token holders receive rewards from various aspects of the Portal ecosystem.  </p>
           <p>Portal is built in such a way that the platform strengthens all mutual elements but also grows as individual sectors develop. $PRTL token holders will benefit and $PRTL will gain value when these overall sectors develop and continue to grow.</p></div>
         
        </div> */}
        
        <div className="clear"></div>
    </div></div>
</section>














<div className="menu_marwu2" id="roadmapid">
<div className="menu_marq_item2">

  <div className="marquee2">
    <div className="marquee_inner2" aria-hidden="true">
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    <p className="split">roadmap</p>
    </div>
  </div>
</div>


</div>


<section className="quarter-outer">
<div className="container_rows"> 
<div className="quarter-logo"><img  src={quarterlisticon} alt=""/></div>
<Slider {...settingsquarter}>
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q4 2021</div>
<div className="quarter-list"><p>Initial platform development</p><p>Setting up smart contracts</p></div>
</div></div>
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q1 2022</div>
<div className="quarter-list"><p>Team expansion </p><p>Whitepaper released</p><p>First projects for connection with Portal</p></div>
</div></div>
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q2 2022 </div>
<div className="quarter-list"><p>First land bought in metaverses</p><p>More investors on board</p><p>Building more connections with projects</p></div>
</div></div>
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q3 2022</div>
<div className="quarter-list"><p>Portal token sale</p><p>Boosting Portal marketing</p><p>Staking tokens on Portal platform</p></div>
</div></div>


{/*<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q4 2022</div>
<div className="quarter-list"><p>Research for Portal's metaverse</p><p>Expanding Portal metaverse headquaters</p><p>First usable NFT asset designs</p></div>
      </div></div>*/}
{/*<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q1 2023</div>
<div className="quarter-list"><p>Releasing Portal mini game</p><p>Expanding the Portal NFT assets</p><p>Deeper integrations with projects</p></div>
</div></div>*/}
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q04 2022</div>
<div className="quarter-list"><p>Research Portal's own metaverse</p><p>Token presale stage</p><p>First NFT asset designs to be used in multiple metaverse</p></div>
</div></div>
<div className="quarter-loop"><div className="quarter-loop-innr">
<div className="quarter-year">Q01 2023</div>
<div className="quarter-list"><p>Token launch</p><p>Expanding the Portal NFT assets</p><p>Start building Portal mini games and own metaverse</p></div>
</div></div>

</Slider>
</div>
</section>


<section className="portal-barnds-outer backers-partner">
<div className="container_rows">
<h2>BACKERS & PARTNERS</h2>
<div className="backers-loop">
<ul>
<li><img  src={backerslogo1} alt="icon"/></li>
{/*<li><img  src={backerslogo2} alt="icon"/></li>*/}
<li><img  src={backerslogo3} alt="icon"/></li>
<li><img  src={backerslogo4} alt="icon"/></li>
{/*<li><img  src={backerslogo5} alt="icon"/></li>
<li><img  src={backerslogo6} alt="icon"/></li>*/}
</ul>
</div>
</div>
</section>

<section className="portal-barnds-outer portal-metavrse-land">
<div className="container_rows">
<h2>PORTAL METAVERSE LAND</h2>
<div className="backers-loop">
<ul>
<li><img  src={landlogo1} alt="icon"/></li>
<li><img  src={landlogo2} alt="icon"/></li>
<li><img  src={landlogo3} alt="icon"/></li>
</ul>
</div>
</div>
</section>







<section className="portal-team-outer" id="ourteamid">
<div className="container_rows"> 
<div className="portal-team-hed"><h2>Portal Team</h2></div>


<Slider {...settingportalteam}>


<div className="portal-loop">
<div className="portal-img"><img  src={teamicon1} alt="icon"/></div>
<div className="portal-tile">Rien</div>
<div className="portal-fondr"><p>Founder<br />Marketing <br />Creative <br />Blockchain</p></div>
</div>

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon2} alt="icon"/></div>
<div className="portal-tile">Jor</div>
<div className="portal-fondr"><p>CTO<br />Full Stack<br />Blockchain<br />Developer</p></div>
</div>


<div className="portal-loop">
<div className="portal-img"><img  src={teamicon3} alt="icon"/></div>
<div className="portal-tile">Adri</div>
<div className="portal-fondr"><p>CMO<br />Business Builder<br /> Blockchain<br />Start-ups</p></div>
</div>

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon4} alt="icon"/></div>
<div className="portal-tile">Benji</div>
<div className="portal-fondr"><p>Marketing<br />GROWTH<br />Hacker<br />Community+</p></div>
</div>

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon5} alt="icon"/></div>
<div className="portal-tile">Dan</div>
<div className="portal-fondr"><p>Marketing<br />Community+<br />NFT Specialist<br />Partnerships</p></div>
</div>


<div className="portal-loop">
<div className="portal-img"><img  src={teamicon6} alt="icon"/></div>
<div className="portal-tile">Alv</div>
<div className="portal-fondr"><p>Blockchain Dev<br />Full Stack<br /> Smart Contracts<br />High-tech</p></div>
</div>

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon7} alt="icon"/></div>
<div className="portal-tile">Mer</div>
<div className="portal-fondr"><p>designer<br />art direction<br /> Blockchain<br />tech</p></div>
</div>

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon8} alt="icon"/></div>
<div className="portal-tile">Gab</div>
<div className="portal-fondr"><p>NFT Specialist<br />Community Builder<br />GROWTH Hacker<br />Start-ups</p></div>
</div>



<div className="portal-loop">
<div className="portal-img"><img  src={teamicon4} alt="icon"/></div>
<div className="portal-tile">Faouzi</div>
<div className="portal-fondr"><p>ADVISOR<br /> OWNER<br /> DUTCH GAME<br /> STUDIO </p></div>
</div>


<div className="portal-loop">
<div className="portal-img"><img  src={teamicon7} alt="icon"/></div>
<div className="portal-tile">Federico</div>
<div className="portal-fondr"><p>ADVISOR<br />ENTREPRENEUR<br /> BLOCKCHAIN<br />MARKETING</p></div>
</div>

{/*<div className="portal-loop">
<div className="portal-img"><img  src={teamicon4} alt="icon"/></div>
<div className="portal-tile">Ian</div>
<div className="portal-fondr"><p>ADVISOR<br /> FOUNDER <br />FERRUM NETWORK <br />BLOCKCHAIN</p></div>
</div>*/}

<div className="portal-loop">
<div className="portal-img"><img  src={teamicon3} alt="icon"/></div>
<div className="portal-tile">Okou</div>
<div className="portal-fondr"><p>ADVISOR<br /> GROWTH+ <br />BLOCKCHAIN<br /> DEVELOPMENT</p></div>
</div>


</Slider>
</div>
</section>



      <Footer/>
    </div>
    </>
  )
}

export default Homapage
