import React from 'react'
import { Link } from 'react-router-dom'
import logo from "./images/logo.png"

const Header = () => {


    
  return (
    <div className='header_waspper'>
    <header className="site-header">
        
    <div className="container_rows">
        <div className="siteheader">
        <div className="logo_box">
        <div className="logo_icon">
            <Link to=""> <img  src={logo} alt="logo"/></Link>
        </div>
         <div className="logo_text">
            <p>Hybrid social metaverse platform</p>
        </div>
        </div>





<div className="wallet_box_outer">
        <div className="wallet_box">
        <Link to="#"> Connect Wallet</Link>
        </div>


</div>      

<div className="clear"></div>


    </div>
</div>


</header>
    </div>
  )
}

export default Header
